<template>
    <main id="main" class="o-main o-main--not-found">
        <header class="c-article__header c-intro o-container o-container--full o-container--no-pad">
            <div class="c-intro__img c-intro__img-no-offset" :style="{ backgroundImage: `url(${imgUrl})` }">
            </div>
        </header>
        <div class="o-container u-mb--top-3">
            <span class="c-txt c-txt--bold c-txt--upper u-color--primary">Es tut uns Leid</span>
            <h1>Leider konnten wir die aufgerufene Seite nicht finden.</h1>
            <router-link class="c-link c-link--has-icon c-link--arrow u-mb--top-2" to="/">
                Zur Startseite
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 15" v-bind:svg-inline="''" v-bind:class="'c-icon c-icon--arrow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 1l6 6.5L1 14"/></svg>
            </router-link>
        </div>
    </main>
</template>

<script>
    export default {
        name: 'NotFound',

        data () {
            return {
                imgUrl: `${process.env.BASE_URL}Apology_Shutterstock-s_karau.jpg`
            }
        },

        mounted() {
            document.title = 'Seite nicht gefunden | Rheiner'
        }

    }
</script>

<style lang="scss" src="@/styles/components/_intro.scss"></style>